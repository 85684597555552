import { createSlice,PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, AppThunk} from '../../store'
import profileData from '../../data/profile.json'

export interface ProfileState {
  value: {};
  status: 'idle' | 'loading' | 'failed'
}

const initialState: ProfileState = {
  value: profileData,
  status: 'idle'
}

export const profileSlice = createSlice({
  name: 'customerProfile',
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.value = {...state.value, ...action.payload}
      state.value = Object.assign(state.value, action.payload)
    },
    getProfile: (state) => {
      state.value = 'senthil'
    },

  },
})

// Action creators are generated for each case reducer function
export const { updateProfile, getProfile } = profileSlice.actions
export const selectProfile = (state: RootState) => state.profile.value

export default profileSlice.reducer
