import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import {Grid,Card,CardContent,Typography, Button} from '@mui/material'; // Grid version 1
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { json2csv } from 'json-2-csv';
import csvDownload from 'json-to-csv-export'
import xlsx from "json-as-xlsx"
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DEFAULTS } from '../../../../Constants/Constants'
import { DataGrid } from '@mui/x-data-grid';

let settings = {
  fileName: "MySpreadsheet", // Name of the resulting spreadsheet
  extraLength: 3, // A bigger number means that columns will be wider
  writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
  writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
  RTL: true, // Display the columns from right-to-left (the default value is false)
}

const getPreviousDay = (days:any) => {
  const date = new Date()
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - days);

  return previous;
}

function Reports() {
  const [records, setReportData] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [dataFetched, setDataFetched] = useState(true)

  const getReport = async (data: any) => {

    await axios.post(DEFAULTS.API_URL+'reports', data)
    .then(
      response => setReportData(response.data)
    )
      
  }
 
const generateReport = () => {

  const data = {
    fromDate: selectedFromDate,
    toDate:selectedToDate
  }
  getReport(data)
}

  const [selectedOption, setSelectedOption] = React.useState('Today');

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    const selectedValue = event.target.value
    if (selectedValue === 'Today')
    {
      setSelectedFromDate(new Date)
      setSelectedToDate(new Date)
      setDisabled(true)
    }

    if (selectedValue === 'Yesterday')
    {
      setSelectedFromDate(getPreviousDay(1))
      setSelectedToDate(getPreviousDay(1))
      setDisabled(true)
    }
    if (selectedValue === 'Last 30 Days')
    {
      setSelectedFromDate(getPreviousDay(30))
      setSelectedToDate(new Date)
      setDisabled(true)
    }

    if (selectedValue === 'Custom Dates')
    {
      setSelectedFromDate(new Date)
      setSelectedToDate(new Date)
      setDisabled(false)
    }
  };

  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(new Date);
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(new Date);

  const handleToDateChange = (date: Date | null) => {
    if (selectedFromDate !== null && date !== null && date > selectedFromDate) {
      // If the "from" date is greater than the "to" date, reset it to the previous value
      return;
    }
    setSelectedToDate(date);
  };
  const handleFromDateChange = (date: Date | null) => {
    if (selectedToDate !== null && date !== null && date > selectedToDate) {
      // If the "from" date is greater than the "to" date, reset it to the previous value
      return;
    }
    setSelectedFromDate(date);
  };



  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }
 let json2csvCallback = function (err: any, csv: any) {
  if (err) throw err;
  console.log(csv);
};

const dataToConvert = {
  data: records,
  filename: 'ip_addresses_report',
  delimiter: ','
}
 const getCSV = () =>{
console.log("printing csv")
csvDownload(dataToConvert)

 }
 const getExcel =() =>{
  xlsx(records, settings)
 }
    return (
      <div>

        <Typography variant='h4'>Reports</Typography>
            <Card variant="outlined">
                <CardContent>
                <Grid container spacing={3} style={{marginBottom:20}}>
              <Grid item xs={12} md={3} lg={3}>
             
      <InputLabel>Select an option</InputLabel>
      <Select
        value={selectedOption}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem value="Today">Today</MenuItem>
        <MenuItem value="Yesterday">Yesterday</MenuItem>
        <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
        <MenuItem value="Custom Dates">Custom Dates</MenuItem>
      </Select>

    </Grid>
    <Grid item xs={12} md={3} lg={3}>
    <InputLabel>Select from date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="From date"
        value={selectedFromDate}
        onChange={handleFromDateChange}
        readOnly = {disabled}
      />
    </LocalizationProvider>
    </Grid>
    <Grid item xs={12} md={3} lg={3}>
    <InputLabel>Select to date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="To date"
        value={selectedToDate}
        onChange={handleToDateChange}
        readOnly = {disabled}
        
      />
    </LocalizationProvider>
    </Grid>
    <Grid item xs={12} md={3} lg={3}>
    <br/>
    <Button type="button" variant="contained" onClick={()=>generateReport()}>Generate Report</Button>
    </Grid>
    </Grid>
    <Divider style={{marginBottom:20}}/>
{ records.length > 0 && (
  <>
    <Button type="button" variant="contained" onClick={()=>getCSV()} style={{backgroundColor:'#eb4034'}}>Export to CSV</Button>     
    {/* <Button type="button" variant="contained" onClick={()=>getExcel()} style={{backgroundColor:'#eb4034'}}>Export to Excel</Button>  */}
    <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Order Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Shipment Date</TableCell>
            <TableCell>Delivery Date</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Method</TableCell>
            <TableCell >SE Rate(USD)</TableCell>
            <TableCell >SH Rate(USD)</TableCell>
            <TableCell >Tax (USD)</TableCell>
            <TableCell >Total(USD)</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {records.map((row: any, idx: any) =>  (
            <TableRow key={row['_id']}>
              <TableCell>
              {row['shipmentId']}</TableCell>
              <TableCell>{getDate(row['shipmentOrderDate'])}</TableCell>
              <TableCell>{getDate(row.shipmentOrderRecord.shipmentOrder.rate.ship_date)}</TableCell>
              <TableCell>{getDate(row.shipmentOrderRecord.shipmentOrder.rate.estimated_delivery_date)}</TableCell>
              <TableCell>{getName(row)}</TableCell>
              
              <TableCell>{row.shipmentOrderRecord.shipmentOrder.rate.service_type}</TableCell>
              <TableCell align="right">{Number(row['shipmentAmount']).toFixed(2)}</TableCell>
              <TableCell align="right">{Number(row['shipmentTotal']).toFixed(2)}</TableCell>
              <TableCell align="right">{Number(row['shipmentTax']).toFixed(2)}</TableCell>
              <TableCell align="right">{Number(row['shipmentGrandTotal']).toFixed(2)}</TableCell>
              <TableCell style={{ color: row['shipmentStatus'] === 'COMPLETE' ? 'green' : 'red' }}>
  {row['shipmentStatus']}
</TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
      </>
)}
      </CardContent>
      </Card>

      </div>
    );
  }

  

  export default Reports;