import React, {useState, useEffect} from 'react';
import {Container, Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio, TextField, Button, Box, Typography} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { DEFAULTS } from '../../../../Constants/Constants'
import { getDate } from '../../../../util/DisplayUtils';


function Confirmation() {
  const [shipmentOrder, setShipmentOrder] = useState<{[key: string]: any}>({});
  const [shipmentOrderFull, setShipmentOrderFull] = useState<{[key: string]: any}>({});
  const { shipmentid } = useParams();
  const token = localStorage.getItem('admin-token');
  let navigate = useNavigate();
  
  const createLabel = async (formbody: any, shipmentid: any) => {

    await axios.post(DEFAULTS.API_URL+`shipment/${shipmentid}`, '',
    {headers: {
      'Authorization': `Bearer ${token}`
    }}
    )
    .then(
      response => {
        console.log(response)
        if (response.data.shipmentId)
        {
            setShipmentOrder(response.data.shipmentOrderRecord.shipmentOrder)
            setShipmentOrderFull(response.data)
            console.log("order",response.data.shipmentOrderRecord)
            
        }
      }
    )
      
  }
  useEffect(() => {
    createLabel('', shipmentid)
    //setLabel(Order1)

  },[]);
  
     return (
      <Container>
            <Typography variant='h5'>Order Confirmation</Typography>
            <Box>
        <Card>
<CardContent>
<p>Your shipment order is placed successfully</p>


<Grid container spacing={6} style={{marginTop:10, marginBottom:10}}>
          <Grid item xs={12} md={4}> Order No # : {shipmentid}</Grid>
          <Grid item xs={12} md={4}> Order Date # : {getDate(shipmentOrderFull.shipmentOrderDate)}</Grid>
          <Grid item xs={12} md={4}> Status : {shipmentOrderFull.shipmentStatus}</Grid>
</Grid>
    <Divider />
            <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
             Shipment Details
            {shipmentOrder?.rate ? (
                  <>
                  <ul style={{listStyle:'none', paddingLeft:0}}>
                  <li>Carrier Name : {shipmentOrder.rate.carrier_nickname} </li>
                  <li>Ship Date :  {getDate(shipmentOrder.rate.ship_date)}</li>
                  <li>Estimated Delivery Date :  {getDate(shipmentOrder.rate.estimated_delivery_date)} </li>
                  <li> Service Type:  {shipmentOrder.rate.service_type} </li>
                  <li>Guranteed Service:  {shipmentOrder.rate.guaranteed_service? 'Yes': 'No'} </li>
                  <li> Trackable:  {shipmentOrder.rate.trackable? 'Yes': 'No'} </li>
                  </ul>
                  </>
            ):null}
              </Grid>

              <Grid item xs={12} md={3}>
                Ship To
                {shipmentOrder?.shipments?.ship_to ? (
                  <div> 
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.ship_to.name}</li>
                      <li>{shipmentOrder?.shipments.ship_to.address_line1}</li>
                      <li>{shipmentOrder?.shipments.ship_to.city_locality}</li>
                      <li>{shipmentOrder?.shipments.ship_to.postal_code}</li>
                      <li>{shipmentOrder?.shipments.ship_to.state_province}, {shipmentOrder?.shipments.ship_to.country_code}</li>

                      <li>{shipmentOrder?.shipments.ship_to.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={3}>
                Ship From
                {shipmentOrder?.shipments?.ship_from ? (
                  <div>
                    <ul style={{listStyle:'none', paddingLeft:0}}>
                      <li>{shipmentOrder?.shipments.ship_from.name}</li>
                      <li>{shipmentOrder?.shipments.ship_from.address_line1}</li>
                      <li>{shipmentOrder?.shipments.ship_from.city_locality}</li>
                      <li>{shipmentOrder?.shipments.ship_from.postal_code}</li>
                      <li>{shipmentOrder?.shipments.ship_from.state_province}, {shipmentOrder?.shipments.ship_from.country_code}</li>

                      <li>{shipmentOrder?.shipments.ship_from.phone}</li>
                    </ul>
                  </div>
                ) : null}
              </Grid>
              <Divider/>
              
              
                <Grid item xs={12} md={12}> 
                <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Package Id</TableCell>
            <TableCell>Dimensions (L X W X H) </TableCell>
            <TableCell>Weight</TableCell>
            <TableCell>Tracking Number </TableCell>
            <TableCell>Shipping Labels</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>   
                {
  shipmentOrderFull?.shipmentLabel?.packages?.map((orderPack: any, idx: any) => (
    <TableRow>
    <TableCell>{orderPack.package_id}</TableCell>
    <TableCell>{orderPack.dimensions.length} X {orderPack.dimensions.width} x {orderPack.dimensions.height} {orderPack.dimensions.unit.toUpperCase()}</TableCell>
    <TableCell>{orderPack.weight.value} {orderPack.weight.unit.toUpperCase()}</TableCell>
    <TableCell>{orderPack.tracking_number}</TableCell>
    <TableCell><a target="_blank" href={orderPack.label_download.pdf}><PictureAsPdfIcon /></a>
    <a target="_blank" href={orderPack.label_download.zpl}><PrintIcon /></a>
    <a target="_blank" href={orderPack.label_download.png}><ImageIcon /></a></TableCell>

</TableRow>
  )
  )
   
  }
  </TableBody>
      </Table>
  </Grid>

              <Grid item xs={12} md={10}>
                {shipmentOrder?.rate ? (
                  <>

                  { (shipmentOrder.rate.warning_messages.length > 0) ? (
                    
                    <Alert color='warning'>
                    {shipmentOrder.rate.warning_messages.map((warning: any, index: any) => (
                     <p>{warning}</p>
                    ))}
                    </Alert>
                  ) : null}

                  </>

                ): null}
              </Grid>
              <Grid item xs={12} md={2}>
                <ul style={{listStyle:'none', paddingLeft:0}}>
    <li> Charges : $ {Number(shipmentOrderFull.shipmentFinalAmount).toFixed(2)} </li>
    <li> Insurance : $ {Number(shipmentOrderFull.shipmentFinalInsurance).toFixed(2)}</li>
    <li> Others : $ {Number(shipmentOrderFull.shipmentFinalOther).toFixed(2)}</li>
    <li> Total : $ {Number(shipmentOrderFull.shipmentTotal).toFixed(2)}</li>
    </ul>

    </Grid>

            </Grid>
    
</CardContent>
        </Card>
        
      </Box>
      </Container>
    );
  }

  

  export default Confirmation;