import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

import axios from 'axios';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
function Help() {
  //const count = useSelector((state: RootState) => state.counter.value)
  const shipment = useSelector((state: RootState) => state.shipmentOrder.value)

  const dispatch = useAppDispatch();

  
  const profile: {} = shipment
  console.log("store", profile)
  const expensesListResp = async () => {
    await axios.get('http://localhost:3456/carriers', {
    headers: {
                    'authorization': 'Basic MWU4N2M4ZjE3NjgwNGM3OWI2NmViZGJlZGM0ODE3YmY6YjkwZTY2ODNjYWQzNDFiOGE0MjdlZGU0YjYwYjc5Y2E=',
                    'Content-Type': 'application/json'
                }
    })
    .then(
      response => console.log(response.data))
  }
  //expensesListResp();

    return (
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
<Typography variant="h5">Help</Typography>
            <Grid container spacing={3}>
              {/* Chart */}
              {/* <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid> */}
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Email Support</Title>
      <Typography component="p" variant="h4">
        
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      Have any questions? Send us an enquiry via our contact form. We aim at responding to requests by email within 24 hours.
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
        info@ikeocean.com
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Call Support</Title>
      <Typography component="p" variant="h4">
       
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      Our 24-hour hotline is available for Premier plan or above in Australia, United States, United Kingdom, Hong Kong, and Singapore. International rates apply for calls from other regions.
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
        +1 678 261 7328s
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Raise Support Ticket</Title>
      <Typography component="p" variant="h4">
        
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        Our support team is here to help 24/7. Raise a support ticket if you have any questions
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          Support ticket
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                 
                </Paper>
              </Grid>
            </Grid>
      
          </Container>
    );
  }

  

  export default Help;