import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../../../Constants/Constants'
type MyState = Array<any> | Record<string, any>;
const convertFirestoreTimestampToDate = (timestamp: any): string => {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6); // Convert Firestore Timestamp to JavaScript Date
  return date.toLocaleString(); // Convert Date to a readable date and time format
};



function Quote() {

  const pre = (ship_type: any) => {
    switch (ship_type) {
      case 'air':
        return "A1";
      case 'ocean':
        return "S1";
        case 'local-courier':
          return "L1";
      default:
        return "T1";
    }
  };
  const { id } = useParams();
  const [quote, setQuote] = useState<{[key: string]: any}>({});

  const token = localStorage.getItem('admin-token');
  let navigate = useNavigate();
  const getQuote = async () => {
  await axios.get(DEFAULTS.API_URL+`quote/${id}`)
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        setQuote(response.data)
         
      }
    }
  )
    
}
useEffect(() => {

getQuote()

},[])

    return (
      <div>
        
        <Container>
        <Typography variant="h5">Quote Detail : {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}</Typography>
            <Card variant="outlined">
                <CardContent>
                {(Object.keys(quote).length !== 0) && <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3}>
                  <span className="Qhead">Quote Id /Zoho Quote Id</span><br />
                {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId} | {quote?.zohoQuoteId}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Quote Date</span><br />
                  {convertFirestoreTimestampToDate(quote?.quoteDate)}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Assigned To</span><br />
                  {quote?.quoteAssigned?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Ship Date</span><br />
{quote?.quoteDetails?.ship_date}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Mobile</span><br />
                {quote?.quoteMobile}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Name</span><br />
                  {quote?.quoteName?.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Email</span><br />
                  {quote?.quoteEmail?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Requester</span><br />
{quote?.quoteDetails?.requester_type?.toUpperCase()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Shipment Type</span><br />
                {quote?.quoteDetails?.ship_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Load Type</span><br />
                  {quote?.quoteDetails?.load_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Inco Term</span><br />
                {quote?.quoteDetails?.inco_term?.toUpperCase()}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Currency</span><br />
{quote?.quoteDetails?.currency?.toUpperCase()}
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From</span><br />
                {quote?.quoteDetails?.from_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From Address</span><br />
                {quote?.quoteDetails?.from_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To</span><br />
                {quote?.quoteDetails?.to_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To Address</span><br />
                {quote?.quoteDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>

                {(quote?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={4}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                {quote?.quoteDetails?.additionals?.map((item: any, index: number) => (
                 <tr><td>{item.label}</td><td>{item.required? 'YES':'NO'}</td></tr>
                 ))}
</table>
                </Grid>)}
                

                {(quote?.quoteType === 'local-courier') && <Grid item xs={12} sm={12} md={8}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
 
    <tr >
      <td>{quote?.quoteDetails?.item?.quantity}</td>
                      <td>{quote?.quoteDetails?.item?.packaging}</td>
                      <td>{quote?.quoteDetails?.item?.weight.value}  {quote?.quoteDetails?.item?.weight.unit}</td>
                      <td>{quote?.quoteDetails?.item?.dimensions.height} X {quote?.quoteDetails?.item?.dimensions.width} X {quote?.quoteDetails?.item?.dimensions.length} {quote?.quoteDetails?.item?.dimensions.unit}</td>
  </tr>

</table></Grid>}
{(quote?.quoteDetails?.load_type === 'lcl' || quote?.quoteDetails?.load_type === 'ltl' || (quote?.quoteType === 'air')) && <Grid item xs={12} sm={12} md={8}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
  {quote?.quoteDetails?.items?.map((item: any, index: number) => (
    <tr key={index}>
      <td>{item.quantity}</td>
                      <td>{item.packaging}</td>
                      <td>{item.weight.value}  {item.weight.unit}</td>
                      <td>{item.dimensions.height} X {item.dimensions.width} X {item.dimensions.length} {item.dimensions.unit}</td>
  </tr>
  ))}
</table></Grid>}

{(quote?.quoteDetails?.load_type === 'fcl' || quote?.quoteDetails?.load_type === 'ftl') && <Grid item xs={12} sm={12} md={8}>
<span className="Qhead">Cargo Details</span><br /><br />
<table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr><td>Quantity</td><td>{quote?.quoteDetails?.full_load_quantity}</td></tr>
                      
                    <tr><td>Container</td><td>{quote?.quoteDetails?.full_load_container}</td></tr>
                    <tr><td>Container Type</td><td>{quote?.quoteDetails?.full_load_container_type}</td></tr>

                    <tr><td>Weight</td><td>{quote?.quoteDetails?.full_load_weight} - {quote?.quoteDetails?.full_load_weight_unit}</td></tr>
                    <tr><td>Commodity</td><td>{quote?.quoteDetails?.commodity}</td></tr>
                      </table>
  
  

  
  
  
</Grid>}  



                </Grid>}
</CardContent>
</Card>
      </Container>

      </div>
    );
  }

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }
  
  
  export default Quote;