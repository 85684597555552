import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet, useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';

import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1
import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment';
import axios from 'axios';

import Autocomplete from '@mui/material/Autocomplete';

import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'
const columns = [
  { key: 'shipmentId', name: 'Order Id' },
  { key: 'shipmentOrderDate', name: 'Date' },
  { key: 'ship_date', name: 'Shipment Date' },
  { key: 'estimated_delivery_date', name: 'Delivery Date' },
  { key: 'shipperName', name: 'Ship To' },
 { key: 'service_type', name: 'Method' },
 { key: 'shipmentGrandTotal', name: 'Amount(USD)' },
 { key: 'shipmentStatus', name: 'Status' },

];

type customerType = {
  [key: string]: any;
}
function Customer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [record, setRecords] =useState<customerType>();
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + `customer/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
     
  
      setRecords(response.data[0]);
      setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();
  }, []);

  

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
<>

<Typography variant='h4'>Customer</Typography>
    <Card variant="outlined">
        <CardContent>
        <Table size="small">
        <TableBody>
        <TableRow>
          <TableCell> Name: </TableCell>
          <TableCell>{record?.firstname.toUpperCase()} {record?.lastname.toUpperCase()}</TableCell>
        </TableRow>
        <TableRow><TableCell>Username: </TableCell><TableCell>{record?.username.toUpperCase()}</TableCell></TableRow>
        <TableRow><TableCell>Company: </TableCell><TableCell>{record?.company.toUpperCase()}</TableCell></TableRow>
        <TableRow><TableCell>User Id:</TableCell><TableCell>{record?.userid}</TableCell></TableRow>
        <TableRow> <TableCell>Status: </TableCell><TableCell>{record?.status}</TableCell></TableRow>
        <TableRow> <TableCell>Account Id </TableCell><TableCell>{record?.zohoAccountId} <Link href={`https://crm.zoho.com/crm/org848930304/tab/Accounts/${record?.zohoAccountId}`} target="_blank" rel="noopener noreferrer">
  <LaunchIcon />
</Link></TableCell></TableRow>
        <TableRow> <TableCell>Contact Id </TableCell><TableCell>{record?.zohoContactId} <Link href={`https://crm.zoho.com/crm/org848930304/tab/Contacts/${record?.zohoContactId}`} target="_blank" rel="noopener noreferrer">
  <LaunchIcon />
</Link></TableCell></TableRow>
        <TableRow><TableCell>Wallet: </TableCell><TableCell>${Number(record?.wallet).toFixed(2)}</TableCell></TableRow>
        </TableBody>
          </Table>
          </CardContent>
          </Card>
</>
    );
  }

  

  export default Customer;