import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography,Modal} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';

import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1

import moment from 'moment';
import axios from 'axios';

import Autocomplete from '@mui/material/Autocomplete';

import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'
const columns = [
  { key: 'shipmentId', name: 'Order Id' },
  { key: 'shipmentOrderDate', name: 'Date' },
  { key: 'ship_date', name: 'Shipment Date' },
  { key: 'estimated_delivery_date', name: 'Delivery Date' },
  { key: 'shipperName', name: 'Ship To' },
 { key: 'service_type', name: 'Method' },
 { key: 'shipmentGrandTotal', name: 'Amount(USD)' },
 { key: 'shipmentStatus', name: 'Status' },

];


function Customers() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)

  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + 'customers', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      let records = response.data;
      records = records.sort((a: any, b: any) => {
        let da: any = new Date(b.userid);
        let db: any = new Date(a.userid);
        return da - db;
      });
  
  
      setRecords(records);
      setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();
  }, []);

  const columns = [
    {
      field: "userid",
      headerName: "Customer Id",
      width: 100,
      renderCell: (params: any) => (
        <a href={`/portal/customer/${params.row.userid}`}>{params.row.userid}</a>
      )
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.firstname.toUpperCase()
      )
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.lastname ? params.row.lastname.toUpperCase() : ''
      )
    },
    {
      field: "company",
      headerName: "Company Name",
      width: 300,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.company ? params.row.company.toUpperCase() : ''
      )
    },
    {
      field: "username",
      headerName: "Email",
      width: 300,
      sortable: true,
      filter:false
    },
    {
      field: "wallet",
      headerName: "Wallet",
      valueGetter: (params: any) => {
        //console.log("params ==", params.row.shipping_amount.amount);
        //params.rows.shipping_amount.amount
        return ((Number(params.row.wallet) > 0 ) ?Number(params.row.wallet).toFixed(2): '0')
      },
      width: 100,
      sortable: true,
      filter:false
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      sortable: true,
      filter:false
    }
  ];

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
<>

<Typography variant='h4'>Customers</Typography>
    <Card variant="outlined">
        <CardContent>

        {/* {records && (<DataGrid 
        columns={columns} 
        rows={records}
        />)} */}
       <DataGrid
        rows={records}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.userid}
        loading={dataFetched}       
        style={{marginBottom:10}}
      />
          </CardContent>
          </Card>

</>
    );
  }

  

  export default Customers;