import { createSlice,PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, AppThunk} from '../../store'
import customerOrder from '../../data/shipment.json'

export interface ShipmentState {
  value: {};
  status: 'idle' | 'loading' | 'failed'
}

const initialState: ShipmentState = {
  value: customerOrder,
  status: 'idle'
}

export const shipmentSlice = createSlice({
  name: 'shipmentOrder',
  initialState,
  reducers: {
    updateOrder: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.value = {...state.value, ...action.payload}
      state.value = Object.assign(state.value, action.payload)
    },
    getOrder: (state) => {
      state.value = 'senthil'
    },

  },
})

// Action creators are generated for each case reducer function
export const { updateOrder, getOrder } = shipmentSlice.actions
export const selectShipmentOrder = (state: RootState) => state.shipmentOrder.value

export default shipmentSlice.reducer
