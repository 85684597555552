import React , {useState} from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
export default function CopyRight(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props} style={{position:'absolute', bottom: '10px', textAlign:'center'}}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.ikeocean.com" target='blank'>
          IKEOCEAN
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }