import React, {useState, useEffect} from 'react';
import {Container, Button, Card, CardContent, Typography, Box, RadioGroup, Radio, CircularProgress} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import moment from 'moment';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'
type shipmentOrderType = {
  [key: string]: any;
}


function CourierInfo() {
  let SHIPMENTORDER : shipmentOrderType = JSON.parse(JSON.stringify(useAppSelector(selectShipmentOrder)))
  const dispatch = useAppDispatch();

  console.log("SHIPMENTORDER", SHIPMENTORDER)
  const [shipRates, setShipRates] = useState([])
  const [shipRate, setRate] = useState('')
  const [dataFetched, setDataFetched] = useState(true)
  const [carrierCode, setCarrier] = useState('')
  let navigate = useNavigate();
  const formbodyOld: any = {
    "rate_options": {
      "carrier_ids": [
        "se-4299739"
      ]
    },
    "shipment": {
      "validate_address": "no_validation",
      "ship_to": {
        "name": "Amanda Miller",
        "phone": "555-555-5555",
        "address_line1": "525 S Winchester Blvd",
        "city_locality": "San Jose",
        "state_province": "CA",
        "postal_code": "95128",
        "country_code": "US",
        "address_residential_indicator": "yes"
      },
      "ship_from": {
        "company_name": "Example Corp.",
        "name": "John Doe",
        "phone": "111-111-1111",
        "address_line1": "4009 Marathon Blvd",
        "address_line2": "Suite 300",
        "city_locality": "Austin",
        "state_province": "TX",
        "postal_code": "78756",
        "country_code": "US",
        "address_residential_indicator": "no"
      },
      "packages": [
        {
          "weight": {
            "value": 1.0,
            "unit": "ounce"
          }
        }
      ]
    }
  };

  const formbody: any = {
    "rate_options": {
      "carrier_ids": [
        "se-4299739"
      ]
    },
    "shipment": {}
  };

const clickNext = () => {

const selectedRate = shipRates.filter(function (rates1, index) {
return rates1["rate_id"] == shipRate;
});
SHIPMENTORDER.shipmentOrder.rate = selectedRate[0];
             
console.log("shipmentData.shipments.packages", SHIPMENTORDER)
dispatch(updateOrder( SHIPMENTORDER))
navigate('/portal/shipment/add-ons');

}

  const expensesListResp = async () => {
    //await axios.post(DEFAULTS.API_URL+ 'rates', formbody)
    await axios.post('https://api2.ikeocean.com/rates.php', formbody)
    .then(
      response => {
        setShipRates(response.data.rate_response.rates)
        setDataFetched(false)

      })
  
      
  }
  useEffect(() => {
    // Update the document title using the browser API
    formbody.shipment= SHIPMENTORDER.shipmentOrder.shipments
    console.log("formbody", formbody)
    expensesListResp();
    console.log("radiochecked", shipRates[0])
    //setChecked(shipRates[0].rate_id);
  },[]);


  
  const columns = [
    // {
    //   field: "rate_id",
    //   headerName: "",
    //   width: 50,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <Radio 
    //     checked={radioChecked === params.rate_id}
    //     //checked={cellValues.id === radioChecked}
    //     //onChange={setSelectionModel(params.rate_id)}
    //     name="radio-buttons"
    //     value={params.rate_id} />
    //   )
    // },
    {
      field: "carrier_nickname",
      headerName: "Code",
      width: 100,
      renderCell: (params: any) => (
        <img src="https://assets.easyship.com/app/courier-logos/usps-mini.svg" />
      )
    },
    {
      field: "service_type",
      headerName: "Service",
      width: 300,
      sortable: true,
      filter:false
    },
    {
      field: "delivery_days",
      headerName: "Days",
      width: 100,
      sortable: true,
      filter:false
    },
    {
      field: "shipping_amount",
      headerName: "Charges",
      valueGetter: (params: any) => {
        //console.log("params ==", params.row.shipping_amount.amount);
        //params.rows.shipping_amount.amount
        return (params.row.shipping_amount.amount + (params.row.shipping_amount.amount * 0.1)).toFixed(2)
      },
      width: 100,
      sortable: true,
      filter:false
    },
    {
      field: "estimated_delivery_date",
      headerName: "EDD",
      width: 200,
      sortable: true,
      filter:false,
      valueGetter: (params: any) => {
        //console.log("params ==", params.row.shipping_amount.amount);
        //params.rows.shipping_amount.amount
        return moment(params.row.estimated_delivery_date).format('MMMM Do YYYY, h:mm:ss a')
   
      }
      
    }
  ];

const handleRowlick = (row: any) =>{
  console.log("row",row.id, row.row.service_code)
  setCarrier(row.row.service_code)
  setRate(row.row.rate_id)
}



    return (
<Container>
<Typography variant='h5' align="left" display={'inline'}>Select Courier</Typography>
      <Typography variant='body1' align="right" display={'inline'}>(Step 3 of 5)</Typography>
    <Box>
                   
<Card><CardContent>

<DataGrid
        rows={shipRates}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.rate_id}
        loading={dataFetched}
        onRowClick= {handleRowlick}
        style={{marginBottom:10}}
      />

      <Button
                  type="button"
                  variant="contained"
                  onClick={()=>{navigate('/portal/shipment/shipment-info')}}
                  >Previous</Button>
      <Button 
        style={{marginLeft:10}}
        variant="contained"
        disabled={carrierCode === ''}
        onClick= {clickNext}>
        Next
       </Button>
</CardContent>
</Card>
</Box>
</Container>
    );
  }

  

  export default CourierInfo;