import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { DEFAULTS } from '../../../../Constants/Constants'
import axios from 'axios';

// Generate Order Data
function createData(
  id: number,
  date: string,
  name: string,
  shipTo: string,
  paymentMethod: string,
  amount: number,
) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '16 Mar, 2019',
    'Elvis Presley',
    'Tupelo, MS',
    'VISA ⠀•••• 3719',
    312.44,
  ),
  createData(
    1,
    '16 Mar, 2019',
    'Paul McCartney',
    'London, UK',
    'VISA ⠀•••• 2574',
    866.99,
  ),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(
    3,
    '16 Mar, 2019',
    'Michael Jackson',
    'Gary, IN',
    'AMEX ⠀•••• 2000',
    654.39,
  ),
  createData(
    4,
    '15 Mar, 2019',
    'Bruce Springsteen',
    'Long Branch, NJ',
    'VISA ⠀•••• 5919',
    212.79,
  ),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}



export default function Orders() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    // setLoading(true)
    const response = await fetch(DEFAULTS.API_URL+'orders/all',  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    let records = await response.json();
    console.log("records",records)
    records = records.sort((a: any, b: any) => {
      let da: any = new Date(b.shipmentId)
      let   db: any = new Date(a.shipmentId)
      return da - db;
    });
    setRecords(records);
    }
  useEffect(() => {
    getRecords();

  },[]);

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }
  return (
    <React.Fragment>
      <Title>Recent Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Order Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Shipment Date</TableCell>
            <TableCell>Delivery Date</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Method</TableCell>
            <TableCell >Amount(USD)</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {records?.slice(0, 5).map((row: any, idx: any) =>  (
            <TableRow key={row['_id']}>
              <TableCell>
              <Link color="primary" href="" onClick={()=>getShipment(row['shipmentId'])}>{row['shipmentId']}</Link></TableCell>
              <TableCell>{getDate(row['shipmentOrderDate'])}</TableCell>
              <TableCell>{getDate(row.shipmentOrderRecord.shipmentOrder.rate.ship_date)}</TableCell>
              <TableCell>{getDate(row.shipmentOrderRecord.shipmentOrder.rate.estimated_delivery_date)}</TableCell>
              <TableCell>{getName(row)}</TableCell>
              
              <TableCell>{row.shipmentOrderRecord.shipmentOrder.rate.service_type}</TableCell>
              <TableCell align="right">{Number(row['shipmentGrandTotal']).toFixed(2)}</TableCell>
              <TableCell style={{ color: row['shipmentStatus'] === 'COMPLETE' ? 'green' : 'red' }}>
  {row['shipmentStatus']}
</TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
      {(records.length > 5) && (
      <Link color="primary" onClick={clickShipment} sx={{ mt: 3 }}>
        See more orders
      </Link>
      )}
    </React.Fragment>
  );
}