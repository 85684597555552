import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route,Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircle from '@mui/icons-material/AccountCircle';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import CopyRight from '../../../../auth/Login/CopyRight'
import Person2Icon from '@mui/icons-material/Person2';
import WalletIcon from '@mui/icons-material/Wallet';
import ArticleIcon from '@mui/icons-material/Article';
import PasswordIcon from '@mui/icons-material/Password';


import Paper from '@mui/material/Paper';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateProfile,
  getProfile,
  selectProfile } from '../../../../features/profile/profileSlice'

  type customerProfileType = {
    [key: string]: any;
  }
  
  type adminType = {
    [key: string]: any;
  }

function Navigation() {
  const adminDetailsString = localStorage.getItem('admin-details');
  const ADMINDETAILS: adminType | null = adminDetailsString ? JSON.parse(adminDetailsString) as adminType : null;
  let CUSTOMERPROFILE : customerProfileType = JSON.parse(JSON.stringify(useAppSelector(selectProfile)))
  const dispatch = useAppDispatch();
  console.log("CUSTOMERPROFILE", CUSTOMERPROFILE, CUSTOMERPROFILE.firstname)

    return (
      <div style={{height: '95vh'}}>
        <List>
        <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
              <AccountCircle color='secondary'/>
              </ListItemIcon>
              Howdy! {ADMINDETAILS?.userProfileName} 
            </ListItemButton>
          </ListItem>
        <Divider />
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/"><ListItemText primary="Dashboard" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Person2Icon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/customers"><ListItemText primary="Customers" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Person2Icon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/customer/add"><ListItemText primary="Create Customer" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <RequestQuoteIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/quotes"><ListItemText primary="Quotes" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <RequestQuoteIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/quote/create"><ListItemText primary="Create Quote" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LocalShippingIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/shipments"><ListItemText primary="Shipments" /></Link> 
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/orders"><ListItemText primary="Orders" /></Link> 
            </ListItemButton>
          </ListItem>      
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/commission"><ListItemText primary="Commission" /></Link> 
            </ListItemButton>
          </ListItem>    
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <WalletIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/wallet"><ListItemText primary="Wallet" /></Link> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/reports"><ListItemText primary="Reports" /></Link> 
            </ListItemButton>
          </ListItem> 
          <Divider />
        <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PasswordIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/portal/profile"><ListItemText primary="Change Password" /></Link> 
            </ListItemButton>
          </ListItem> 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon color='secondary'/>
              </ListItemIcon>
              <Link href="/auth/logout"><ListItemText primary="Logout" /></Link> 
            </ListItemButton>
          </ListItem> 
          <Divider />
        </List> 

        <CopyRight sx={{ mt: 5 }} />
        </div>

    );
  }

  

  export default Navigation;