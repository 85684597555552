import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Container,
  Typography,
  Box,
  Divider,

} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

import React from 'react';
import { Formik, Form, Field, FieldArray, getIn } from 'formik';
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateOrder,
  getOrder,
  selectShipmentOrder } from '../../../../features/shipment/shipmentSlice'

type shipmentOrderType = {
  [key: string]: any;
}
const validationSchema = Yup.object().shape({
  shipPackages: Yup.array().of(
    Yup.object().shape({
      weight: Yup.object().shape({
        value: Yup.number().required("required")
      }),
      dimensions: Yup.object().shape({
        height: Yup.number().required("required"),
        width: Yup.number().required("required"),
        length: Yup
        .number()
        .positive().required("Must be greater than 0")
        .required("required" )
        .min(0.1).required("Must be greater than 0")
      })
    })
  )
});

interface MyObj {
  shipments?: any
}

function ShipmentInfo() {
  let SHIPMENTORDER : shipmentOrderType = JSON.parse(JSON.stringify(useAppSelector(selectShipmentOrder)))
  const dispatch = useAppDispatch();
  console.log("SHIPMENTORDER ---", SHIPMENTORDER)
  const [shipment, setShipment] = useState()

  
  
  //let shipmentData : { any: MyObj[] }
  let shipmentData :{[key: string]: any}
  useEffect(() => {
    const localData = localStorage.getItem('shipment')
    if (localData) { // i.e, not null and not empty string 
      // now returnUrl cannot be null, so it must be a string, which is valid to use in this call
       // = setShipment(JSON.parse(localData))
       shipmentData = JSON.parse(localData.toString());
      console.log("statesthipment1111",  shipmentData)
      console.log("statesthipment", shipment)
    }
  }, []);

  let navigate = useNavigate();
  return (<div>
    <Container>
      <Typography variant='h5' align="left" display={'inline'}>Shipment Information</Typography>
      <Typography variant='body1' align="right" display={'inline'}>(Step 2 of 5)</Typography>
      <Box>

        <Card><CardContent>

          <Formik
            initialValues={{
              shipPackages: [
                {   weight: {
                    value: null,
                    unit: "ounce"
                  },
                  dimensions: {
                    unit: "inch",
                    length: null,
                    width: null,
                    height: null
                  }
                }
              ]
            }}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
             


          SHIPMENTORDER.shipmentOrder.shipments.packages = values.shipPackages
             
              console.log("shipmentData.shipments.packages", SHIPMENTORDER)
              dispatch(updateOrder( SHIPMENTORDER))
              navigate('/portal/shipment/courier-info');
              
            }
            }
            render={({ values,touched, errors, handleChange, handleBlur, isValid }) => (
              <Form>
                <FieldArray
                  name="shipPackages"
                >
                  {({ insert, remove, push }) => (

                    <div>
                              <Button variant="contained" type="button" onClick={() => push({
                                weight: {
                                  value: null,
                                  unit: "ounce"
                                },
                                dimensions: {
                                  unit: "inch",
                                  length: null,
                                  width: null,
                                  height: null
                                }
                              })}>
                                Add a Package
                              </Button>
                              <Grid container spacing={1} style={{marginBottom:5,marginTop:5}}>
                                <Grid item xs={12} md={3}>Box Dimenions (L X W X H)</Grid>
                                <Grid item xs={12} md={1}>Unit</Grid>
                                <Grid item xs={12} md={1}>Weight</Grid>
                                <Grid item xs={12} md={1}>Unit</Grid>
                                <Grid item xs={12} md={1}></Grid>
                              </Grid>
                              <Divider />

                      {values.shipPackages.length > 0 &&
                        values.shipPackages.map((shipPackage, index) => {
                          const length = `shipPackages[${index}].dimensions.length`;
                          const touchedLength = getIn(touched, length);
                          const errorLength = getIn(errors, length);

                          const width = `shipPackages[${index}].dimensions.width`;
                          const touchedWidth = getIn(touched, width);
                          const errorWidth = getIn(errors, width);

                          const height = `shipPackages[${index}].dimensions.height`;
                          const touchedHeight = getIn(touched, height);
                          const errorHeight = getIn(errors, height);

                          const dunit = `shipPackages[${index}].dimensions.unit`;
                          const wunit = `shipPackages[${index}].weight.unit`;
                          const wvalue = `shipPackages[${index}].weight.value`;
                          const touchedValue = getIn(touched, wvalue);
                          const errorValue = getIn(errors, wvalue);
                         return (
                          <Grid container spacing={4} key={index} rowSpacing={2} style={{marginBottom:5,marginTop:5}}>



                            <Grid item xs={12} md={1}>
                              <TextField name={length}
                                size="small"
                                value={shipPackage.dimensions.length}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='L'

                            //     helperText={
                            //       touchedLength && errorLength
                            //     ? errorLength
                            //     : ""
                            // }
                          error={Boolean(touchedLength && errorLength)}
                                
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <TextField name={width}
                                size="small"
                                value={shipPackage.dimensions.width}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='W'
                                error={Boolean(touchedWidth && errorWidth)}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <TextField name={height}
                                size="small"
                                value={shipPackage.dimensions.height}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='H'
                                error={Boolean(touchedHeight && errorHeight)}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                            {shipPackage.dimensions.unit}
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <TextField name={wvalue}
                                size="small"
                                value={shipPackage.weight.value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='W'
                                error={Boolean(touchedValue && errorValue)}                               
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                            {shipPackage.weight.unit}
                            </Grid>
                            


                            <Grid item xs={12} md={1}>
                              <Button
                                type="button"
                                variant="outlined"
                                onClick={() => remove(index)} 
                              >
                                <DeleteIcon />
                              </Button>
                            </Grid>
                          </Grid>
                         )
                          
                         })}
                                    </div>
                  )}
                </FieldArray>
                <Divider />        
                <Grid container spacing={1} style={{marginBottom:5,marginTop:5}}>
                                <Grid item xs={6} md={4}>
                                <Button
                          type="button"
                          variant="contained"
                          onClick={() => { navigate('/portal/shipment/basic') }}
                        >Previous</Button>
         <Button type="submit" variant="contained" style={{marginLeft:10}}>Next</Button></Grid>

                                </Grid>
                        
                        


                  



              </Form>
            )}
          />
        </CardContent>
        </Card>
      </Box>
    </Container>
  </div>
  )
}

export default ShipmentInfo;