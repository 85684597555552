import React ,{useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Logo from './assets/images/logo.png';
import Header from './portal/components/Common/Header/Header'
import Navigation from './portal/components/Common/Navigation/Navigation'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#000000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#f44336',
    },
  },
});

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
      const userToken = localStorage.getItem('admin-token');
      if (!userToken || userToken === 'undefined') {
          setIsLoggedIn(false);
      }
      setIsLoggedIn(true);
  }
  useEffect(() => {
      checkUserToken();
  }, [isLoggedIn]);
  return (
    <div style={{height: '100vh'}}>
      <ThemeProvider theme={theme}>
    {isLoggedIn && <Header />}
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={2} md={2} style={{height: '95vh'}}>
    {isLoggedIn && <Navigation />}
    </Grid>
    <Grid item xs={10} md={10} style={{backgroundColor: '#f4f6f8', marginTop:'10px'}}>
    <Outlet />
    </Grid>
    </Grid>
    </ThemeProvider>
</div>

  );
}

export default App;
