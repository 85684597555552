import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';

import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1

import moment from 'moment';
import axios from 'axios';

import Autocomplete from '@mui/material/Autocomplete';

import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'
const columns = [
  { key: 'shipmentId', name: 'Order Id' },
  { key: 'shipmentOrderDate', name: 'Date' },
  { key: 'ship_date', name: 'Shipment Date' },
  { key: 'estimated_delivery_date', name: 'Delivery Date' },
  { key: 'shipperName', name: 'Ship To' },
 { key: 'service_type', name: 'Method' },
 { key: 'shipmentGrandTotal', name: 'Amount(USD)' },
 { key: 'shipmentStatus', name: 'Status' },

];

const convertFirestoreTimestampToDate = (timestamp: any): string => {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6); // Convert Firestore Timestamp to JavaScript Date
  return date.toLocaleString(); // Convert Date to a readable date and time format
};

function Shipments() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + 'orders/all', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      let records = response.data;
      records = records.sort((a: any, b: any) => {
        let da: any = new Date(b.shipmentId);
        let db: any = new Date(a.shipmentId);
        return da - db;
      });
  
      const Rows = records.map((row: any, idx: any) => {
        const record = {
          id: row['shipmentId'],
          shipmentOrderDate: getDate(row['shipmentOrderDate']),
          ship_date: getDate(row.shipmentOrderRecord.shipmentOrder.rate.ship_date),
          estimated_delivery_date: getDate(
            row.shipmentOrderRecord.shipmentOrder.rate.estimated_delivery_date
          ),
          shipperName: getName(row),
          service_type: row.shipmentOrderRecord.shipmentOrder.rate.service_type,
          shipmentGrandTotal: Number(row['shipmentGrandTotal']).toFixed(2),
          shipmentStatus: row['shipmentStatus'],
        };
  
        return record;
      });
      
      setRecords(Rows);
      setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Order Id",
      width: 100,
      renderCell: (params: any) => (
        <a href={`/portal/shipment/${params.row.id}`}>{params.row.id}</a>
      )
    },
    {
      field: "shipmentOrderDate",
      headerName: "Service",
      width: 150,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        convertFirestoreTimestampToDate(params.row.shipmentOrderDate)
      )
    },
    {
      field: "ship_date",
      headerName: "Shipment Date",
      width: 100,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        convertFirestoreTimestampToDate(params.row.ship_date)
      )
    },
    {
      field: "estimated_delivery_date",
      headerName: "Delivery Date",
      width: 100,
      sortable: true,
      filter:false
    },
    {
      field: "shipperName",
      headerName: "Ship To",
      width: 300,
      sortable: true,
      filter:false
    },
    {
      field: "service_type",
      headerName: "Method",
      width: 300,
      sortable: true,
      filter:false
    },
    {
      field: "shipmentGrandTotal",
      headerName: "Amount",
      width: 100,
      sortable: true,
      filter:false
    },
    {
      field: "shipmentStatus",
      headerName: "Status",
      width: 200,
      sortable: true,
      filter:false
    }
  ];

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
<>

<Typography variant='h4'>Shipments</Typography>
    <Card variant="outlined">
        <CardContent>

        {/* {records && (<DataGrid 
        columns={columns} 
        rows={records}
        />)} */}
       <DataGrid
        rows={records}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.id}
        loading={dataFetched}       
        style={{marginBottom:10}}
      />
          </CardContent>
          </Card>
</>
    );
  }

  

  export default Shipments;