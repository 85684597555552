import React, {useState, useEffect, useRef } from 'react';
import {Container,  Button,Card, CardContent,Box} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs';
import { useAcceptJs } from 'react-acceptjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import { DEFAULTS } from '../../../../Constants/Constants'

import { getDate, sortByDate } from '../../../../util/DisplayUtils';
function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}

const authDataProd = { //prod
  apiLoginID: '5shgWG8V5j',
  clientKey: '4Xqe2Ar9VCp6cZZy56gfZ7dr5r555KZM7M4BXB32Ta58AgFDdeSbJ9q5cwV2QK84',
  //clientKey: '28u2H8B4VanT3az3',
};

const authDataTest = {  
  apiLoginID: '2d4Bq8L4',
  clientKey: '387abB5JbfVP975s6x9qZt4EsVxPS7Nfvp8us7qTWSeWtCVw9Tgk9sQNNmAp34s5',
  //clientKey: '4C6js4w5E228E2mf',
};


type BasicCardInfo = {
  cardNumber: string;
  cardCode: string;
  month: string;
  year: string;
};
type shipmentOrderType = {
  [key: string]: any;
}

type walletType = {
  [key: string]: any;
}
function Wallet() {
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const shipment = useSelector((state: RootState) => state.shipmentOrder.value)
  const [response, setResponse] = React.useState<string>('');
  const [wallet, setWallet] = React.useState([]);
  const [walletAmount, setWalletAmount] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [amountValid, setAmountValid] = React.useState(false);
  const [walletRow, setWalletRow] = React.useState<walletType>();
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [walletBalance, setWalletBalance] = React.useState(0);
  const token = localStorage.getItem('admin-token');
  const dispatch = useAppDispatch();
  const handleCCSubmit = async (hostedFormResponse: HostedFormDispatchDataResponse) => {
    setResponse(JSON.stringify(hostedFormResponse, null, 2));
    console.log("walletamount",walletAmount)
    await startPayment(hostedFormResponse)
  };
  
 const handleChange1 = ()=>{

 }

 const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
  const inputAmount = event.target.value;
      if (/^\d+$/.test(inputAmount)) {
  if (Number(inputAmount) < 1) {
    setError('Enter amount greater than 0');
  } else {
    setError(null);   
}
}
else{
  setError('Enter a number');
}
console.log(" session value", getValue())
};


 const walletTotal = (wallet:[]) => {
  const total = wallet.reduce((accumulator, currentValue) => 
    (currentValue['walletType'] ==='CREDIT' ? accumulator + currentValue['walletAmount'] : accumulator - currentValue['walletAmount']), 0);
  console.log("total", total)
  setWalletBalance(total);
};
 
 const getWalletDetails = (walletRow:{})=>{
  console.log("walletRow", walletRow)
  setWalletRow(walletRow)
  setOpenDetail(true)
 }

 const getWallet = async () => {
  let walletResponse :  shipmentOrderType = {}
  await axios.get(DEFAULTS.API_URL + 'wallet/transactions',
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      //walletResponse = response.data
      setWallet(sortByDate(response.data,'walletOrderDate'))
     console.log("wallet", response.data)
     walletTotal(response.data)
    }
  ) 
    
}

const getValue = () => {
  
  if (textFieldRef.current) {
    console.log("getValue", textFieldRef.current.value)
    return Number(textFieldRef.current.value);
  }
  return 0;
};

  const startPayment = async (data: any) => {
      
    const paymentData = data
    paymentData.rechargeAmount = getValue();
    console.log("paymentdata >>>",walletAmount,  paymentData)
    let paymentresponse :  shipmentOrderType = {}
    await axios.post(DEFAULTS.API_URL + 'wallet/add', paymentData,
    {headers: {
      'Authorization': `Bearer ${token}`
    }}
    )
    .then(
      response => {
        paymentresponse = response.data
       console.log("paymentdata2", response.data, paymentresponse.messages.message[0].code)
      }
    )
    
    if (paymentresponse.messages.message[0].code === 'I00001') {
      getWallet()
    }
    else
    {

      console.log("error in payment")
    }
      
  }

  useEffect(() => {
    getWallet()

  },[]);
  const profile: {} = shipment
  console.log("store", profile)

    return (
<>
<Typography variant="h5">Wallet</Typography>
            <Grid container spacing={3}>

              <Grid item xs={12} md={9} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    
                  }}
                >
                  <React.Fragment>

      <Typography component="p" variant="h4">
        ${Number(walletBalance).toFixed(2)}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {(wallet.length > 0) ? (
        <Table size="small">
          <TableHead>
          <TableRow>
          <TableCell>Order</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Customer / Company</TableCell>
            <TableCell>Credit</TableCell>
            <TableCell>Debit</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(wallet?.map((row: any, idx: any) =>  (
             <>
            <TableRow key={row['_id']}>
              <TableCell>{row['walletId']}</TableCell>
              <TableCell>{getDate(row['walletOrderDate'])}</TableCell>
              <TableCell>{row['customerDetails']?.company.toUpperCase()}</TableCell>
              <TableCell style={{color:'green'}}>{(row['walletType']==='CREDIT')? `+$${row['walletAmount']}`:''}</TableCell>
              <TableCell style={{color:'red'}}>{(row['walletType']==='DEBIT')? `-$${row['walletAmount']}`:''}</TableCell>
              <TableCell><Button type="button" variant="text" onClick={()=>getWalletDetails(row)}>Details</Button></TableCell>
            </TableRow>
          
            </>
          
    )))}
    </TableBody>
    </Table>) : <> no records found</>}

            </Typography>
      

      {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
        info@ikeocean.com
        </Link>
      </div> */}
    </React.Fragment>
                </Paper>
              </Grid>
              
             

            </Grid>
      
            

<Modal
  open={openDetail}
  onClose={handleCloseDetail}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"  
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <p> Order Id : {walletRow?.walletId}</p>
    <p> Order Date  : {getDate(walletRow?.walletOrderDate)}</p>
    <p> Amount : ${walletRow?.walletAmount}</p>
    <p> Transaction : {walletRow?.walletType}</p>
    {(walletRow?.walletType ==='DEBIT') && <p> Shipment Order : {walletRow?.walletOrder}</p>}
    <hr/>
    <p> Customer Name : {walletRow?.customerDetails.firstname.toUpperCase()} {walletRow?.customerDetails.lastname.toUpperCase()}</p>
    <p> Company : {walletRow?.customerDetails.company.toUpperCase()}</p>
    <p> Wallet Balance : ${Number(walletRow?.customerDetails.wallet).toFixed(2)}</p>
    <p> Mobile : {walletRow?.customerDetails.mobile}</p>
    <p> username : {walletRow?.customerDetails.username.toUpperCase()}</p>
    </Typography>
  </Box>
</Modal>

</>
    );
  }

  

  export default Wallet;