import React , {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {TextField, Container,Card, CardContent} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {Alert, Grid} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateProfile,
  getProfile,
  selectProfile } from '../../../../features/profile/profileSlice'
  import { DEFAULTS } from '../../../../Constants/Constants'

  type customerProfileType = {
    [key: string]: any;
  }

  type FormError = {
    status: string,
    msg:string
    // Add other properties if needed, for example:
    // message: string;
  };

const theme = createTheme();

const validationSchema = yup.object({
  firstname: yup
  .string()
  .min(3, 'Enter a valid fist name')
  .max(20, 'Enter a valid fist name')
  .required('Enter first name')
  .matches(/^[a-z\s]+$/,'Enter a valid fist name'),
  lastname: yup
  .string()
  .min(3, 'Enter a valid last name')
  .max(20, 'Enter a valid last name')
  .required('Enter last name')
  .matches(/^[a-z\s]+$/,'Enter a valid last name'),
  mobile: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number')
});

const validationSchemaPassword = yup.object({
  password: yup
  .string()
  .min(5, 'Enter a valid password with atleast 5 characters')
  .max(12, 'Enter a valid password')
  .required('Enter a password'),
  passwordNew: yup
  .string()
  .oneOf([yup.ref('password'), ''], 'Passwords must match')
  .required('Enter a password')
});

const validationSchemaEmail = yup.object({
  password: yup
  .string()
  .min(5, 'Enter a valid password with atleast 5 characters')
  .max(12, 'Enter a valid password')
  .required('Enter a password'),
  email: yup
  .string().email()
  .min(9, 'Enter a valid email')
  .max(20, 'Enter a valid email')
  .required('Enter an email'),
});

export default function Profile() {
  const navigate = useNavigate();
  const token = localStorage.getItem('admin-token');
  const[profileError, setProfileError] = useState<FormError>({status:"", msg:""});
  const[loginError, setLoginError] = useState<FormError>({status:"", msg:""});
  const[passwordError, setPasswordError] = useState<FormError>({status:"", msg:""});
  console.log('token', token)
  interface profile  {
    firstname: string;
    lastname: string;
    mobile: string;
    username: string;
    company: string;
    _id:string;
};
  const[formSuccess, setFormSuccess] = useState(false);
  let CUSTOMERPROFILE : customerProfileType = JSON.parse(JSON.stringify(useAppSelector(selectProfile)))
  const dispatch = useAppDispatch();
  const data1 = {
    firstname: 'senthil',
    lastname:'kumar',
    mobile:'121212121',
    username:'test@test.com',
    company:'test company',
    _id:'642d82d3a52c0073e2fd9bb4'

  }
   
    const formik = useFormik({
    initialValues: CUSTOMERPROFILE,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)
     axios.post(DEFAULTS.API_URL+"profile/update", values,
     {headers: {
      'Authorization': `Bearer ${token}`
    }}
     )
     .then(
       response => {
        dispatch(updateProfile(response.data[0]))
      }
     )

    },
  });

  const formikPass = useFormik({
    initialValues: {
      password: '',
      passwordNew: ''

    },
    validationSchema: validationSchemaPassword,
    onSubmit: (values: any) => {
      console.log("values", values)
      axios.post(DEFAULTS.API_URL+"admin/password", values,
      {headers: {
       'Authorization': `Bearer ${token}`
     }}
      )
      .then(
        response => {
        setPasswordError(response.data)
        //resetForm()
       }
      )
 
     },
  });

  const formikEmail = useFormik({
    initialValues: {
      password: '',
      email: CUSTOMERPROFILE.username

    },
    validationSchema: validationSchemaEmail,
    onSubmit: (values: any) => {
      console.log("values", values)
      axios.post(DEFAULTS.API_URL+"profile/email", values,
      {headers: {
       'Authorization': `Bearer ${token}`
     }}
      )
      .then(
        response => {
         dispatch(updateProfile(response.data.user))
         localStorage.setItem('admin-token', response.data.token);
       }
      )
 
     },
  });
  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  //   localStorage.setItem('admin-token', 'nakkkalasaxallasa');
  //   setTimeout(() => {
  //       navigate('/');
  //   }, 500);
  // };

  return (
    <Container>
            
           <Typography variant="h5">Change Password</Typography>
          
          <Box component="form" onSubmit={formikPass.handleSubmit} sx={{ mt: 3 }}>
           <Card>
            <CardContent>
            {passwordError.msg !== '' && (
      <Alert color={(passwordError.status=="success")? "success": "error"}>{passwordError.msg}</Alert>
    )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
           <TextField
                margin="normal"
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                size="small"
                autoComplete="current-password"
                value={formikPass.values.password}
                onChange={formikPass.handleChange}
                error={formikPass.touched.password && Boolean(formikPass.errors.password)}
                helperText={formikPass.touched.password && formikPass.errors.password}
              />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
               <TextField
                margin="normal"
                fullWidth
                name="passwordNew"
                label="Confirm Password"
                type="password"
                id="passwordNew"
                size="small"
                autoComplete="current-password"
                value={formikPass.values.passwordNew}
                onChange={formikPass.handleChange}
                error={formikPass.touched.passwordNew && Boolean(formikPass.errors.passwordNew)}
                helperText={formikPass.touched.passwordNew && formikPass.errors.passwordNew}
              />
              </Grid>
              </Grid>
            <Button
              type="submit"

              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </Button>
</CardContent>
           </Card>
           </Box>


          </Container>
  );
}