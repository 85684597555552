import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import axios from 'axios';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { DEFAULTS } from '../../../../Constants/Constants'

type dataType = {
  [key: string]: any;
}

function Dashboard() {
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  const [records, setRecords] = useState<dataType | null>(null);
  console.log('token', token)
  const getDashboard = async () => {
    // setLoading(true)
    const response = await fetch(DEFAULTS.API_URL+'dashboard',  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    let records = await response.json();
    console.log("records",records)
        setRecords(records);
    }
  useEffect(() => {
    getDashboard();

  },[]);

    return (
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title> Wallet Balance</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
       ${Number(records?.wallet).toFixed(2)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        <Link color="primary" href="/portal/wallet">
          Go to Wallet Transactions
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Shipments</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
      ${Number(records?.order).toFixed(2)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        <Link color="primary" href="/portal/shipments">
          View Shipments
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title> Customers</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
      {Number(records?.userCount)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        <Link color="primary" href="/portal/customers">
          View Customers
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Orders />
                </Paper>
              </Grid>
            </Grid>
            {/* <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button> */}
        <span></span>
        {/* <button
          aria-label="Decrement value"
          onClick={() => dispatch(change({name:'kumar'}))}
        >
          update shipment
        </button> */}
          </Container>
    );
  }

  

  export default Dashboard;