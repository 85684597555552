import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import counterReducer from './counterSlice'
import shipmentOrderReducer from '../src/features/shipment/shipmentSlice'
import profileSlice from '../src/features/profile/profileSlice'
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    shipmentOrder:shipmentOrderReducer,
    profile: profileSlice
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType= void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}


export const useAppDispatch = () => useDispatch<AppDispatch>()

// Infer the `RootState` and `AppDispatch` types from the store itself
